<!-- eslint-disable no-console -->
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import api from "../../../../common/axios";
import Swal from "sweetalert2";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Truck Driver",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      config: {
        api: "driver",
        export: "driver/export",
      },
      tableData: [],

      title: "Truck Driver",
      items: [
        {
          text: "Truck Driver",
          href: "/truck-driver",
        },
        {
          text: "View",
          href: "/truck-driver",
          active: true,
        },
      ],
      addctx: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: "",
      tabIndex: 0,
      tabStatus: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "ktp.ktp_no", label: "No KTP" },
        { key: "name", label: "Nama", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "phone", label: "Telepon", sortable: true },
        { key: "address", label: "Alamat", sortable: true },
        { key: "status_account", label: "Status Account" },
        { key: "status_document", label: "Status Document" },
        { key: "action" },
      ],
      routeBase: "truck-driver",
      filtering: {
        status: "",
      },
      counter: {
        menunggu_upload_dokumen: null,
        menunggu_persetujuan: null,
        menunggu_data_bank: null,
        selesai: null,
      },
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length;
  },
  methods: {
    exportExcel() {
      let _ = this;
      let statusParam = null;
      if (this.tabStatus === null) {
        statusParam = null;
      } else {
        switch (this.tabStatus) {
          case 1:
            statusParam = "menunggu upload dokumen";
            break;
          case 2:
            statusParam = "menunggu persetujuan";
            break;
          case 3:
            statusParam = "menunggu data bank";
            break;
          case 4:
            statusParam = "selesai";
            break;
        }
      }
      let key = this.addctx.filter ? this.addctx.filter : "";
      let sort = this.addctx.sortDesc == true ? "asc" : "desc";
      let url = _.config.export;
      api
        .get(url, {
          params: {
            orderby: _.sortBy,
            sort: sort,
            key: key,
            owner: "driver",
            owner_id: 0,
            account_status: this.filtering.status,
            document_status: statusParam,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          if (response) {
            let blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "DriverReport" + new Date();
            link.click();
          }
        });
    },
    checkVariant(value) {
      return this.tabStatus === value ? "danger" : "outline-danger";
    },
    getItems(ctx, callback) {
      const _ = this;
      let statusParam = null;
      if (this.tabStatus === null) {
        statusParam = null;
      } else {
        switch (this.tabStatus) {
          case 1:
            statusParam = "menunggu upload dokumen";
            break;
          case 2:
            statusParam = "menunggu persetujuan";
            break;
          case 3:
            statusParam = "menunggu data bank";
            break;
          case 4:
            statusParam = "selesai";
            break;
        }
      }
      _.addctx = ctx;
      let filter = ctx.filter ? "&key=" + ctx.filter : "";
      let spliting = ctx.sortBy.split(".");
      let sortByTable = spliting[spliting.length - 1];
      _.sortBy = sortByTable !== "" ? sortByTable : _.sortBy;
      let sort = ctx.sortDesc == true ? "asc" : "desc";

      let url = null;
      if (statusParam) {
        url =
          _.config.api +
          "?per_page=" +
          _.perPage +
          "&page=" +
          _.currentPage +
          "&orderby=" +
          _.sortBy +
          "&sort=" +
          sort +
          "&owner=driver&owner_id=0" +
          "&account_status=" +
          _.filtering.status +
          "&document_status=" +
          statusParam +
          filter;
      } else {
        url =
          _.config.api +
          "?per_page=" +
          _.perPage +
          "&page=" +
          _.currentPage +
          "&orderby=" +
          _.sortBy +
          "&sort=" +
          sort +
          "&owner=driver&owner_id=0" +
          "&account_status=" +
          _.filtering.status +
          filter;
      }
      api
        .get(url)
        .then((resp) => {
          const consume = resp.data.data;
          _.perPage = consume.per_page;
          _.currentPage = consume.current_page;
          _.totalRows = consume.total_data;
          _.counter = consume.counter;
          callback(consume.rows || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },

    truncate(str, n) {
      return str.length > n ? str.slice(0, n - 1) + " ..." : str;
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    gotoShowPage(id) {
      let _ = this;
      _.$router.push("/" + _.routeBase + "/" + id + "/show");
    },

    goToAddPage() {
      let _ = this;
      _.$router.push("/" + _.routeBase + "/add");
    },

    goToEditPage(id) {
      let _ = this;
      _.$router.push("/" + _.routeBase + "/" + id + "/edit");
    },

    deleteData(id) {
      let _ = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          api
            .delete(_.config.api + "/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Berhasil Dihapus",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableFe.refresh();
            })
            .catch((err) => {
              this.$swal({
                icon: "Danger",
                title: "Tidak bisa di hapus",
                text: err.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },

    changePerPage() {
      const _ = this;
      _.$refs.tableFe.refresh();
    },

    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
  },
  watch: {
    "filtering.status"() {
      let _ = this;
      _.$refs.tableFe.refresh();
    },

    tabIndex() {
      if (this.tabStatus === null) {
        if (this.currentPage === 1) {
          this.getItems(this.addctx);
        } else {
          this.currentPage = 1;
        }
      } else {
        this.tabStatus = null;
      }
      this.$refs.tableFe.refresh();
    },
    tabStatus() {
      if (this.currentPage === 1) {
        this.getItems(this.addctx);
      } else {
        this.currentPage = 1;
      }
      this.$refs.tableFe.refresh();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-tabs
          align="left"
          justified
          nav-class="nav-tabs-custom"
          v-model="tabIndex"
        >
          <b-tab title="Semua"></b-tab>
          <b-tab>
            <template v-slot:title>
              <span
                v-if="
                  counter.menunggu_upload_dokumen !== 0 ||
                  counter.menunggu_upload_dokumen !== 0 ||
                  counter.menunggu_data_bank !== 0 ||
                  counter.selesai !== 0
                "
                class="badge-notif mr-2"
                data-badge=""
                >Status Document &nbsp;
              </span>
              <span v-else> Status Document</span>
            </template>
          </b-tab>
        </b-tabs>
      </div>
      <div class="col-md-12 card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-10 col-md-12">
              <div id="tickets-table_length" class="dataTables_length">
                <div class="row" v-if="tabIndex == 1">
                  <div class="col-md-12">
                    <b-tabs class="contianer-fluid navtab-bg" justified pills>
                      <b-tab @click="tabStatus = null">
                        <template v-slot:title>
                          <span class="font-weight-bolder">Semua</span>
                        </template>
                      </b-tab>
                      <b-tab @click="tabStatus = 1">
                        <template v-slot:title>
                          <span class="font-weight-bolder no-wr"
                            >Menunggu Upload Dokumen &nbsp;</span
                          >
                          <span
                            v-show="counter.menunggu_upload_dokumen !== 0"
                            class="badge badge-pill py-1"
                            :class="
                              tabStatus == 1 ? 'badge-light' : 'badge-primary'
                            "
                            >{{ counter.menunggu_upload_dokumen }}</span
                          >
                        </template>
                      </b-tab>

                      <b-tab @click="tabStatus = 2">
                        <template v-slot:title>
                          <span class="font-weight-bolder no-wr"
                            >Menunggu Persetujuan &nbsp;</span
                          >
                          <span
                            v-show="counter.menunggu_persetujuan !== 0"
                            class="badge badge-pill py-1"
                            :class="
                              tabStatus == 2 ? 'badge-light' : 'badge-primary'
                            "
                            >{{ counter.menunggu_persetujuan }}</span
                          >
                        </template>
                      </b-tab>

                      <b-tab @click="tabStatus = 3">
                        <template v-slot:title>
                          <span class="font-weight-bolder no-wr"
                            >Menunggu Data Bank &nbsp;</span
                          >
                          <span
                            v-show="counter.menunggu_data_bank !== 0"
                            class="badge badge-pill py-1"
                            :class="
                              tabStatus == 3 ? 'badge-light' : 'badge-primary'
                            "
                            >{{ counter.menunggu_data_bank }}</span
                          >
                        </template>
                      </b-tab>

                      <b-tab @click="tabStatus = 4">
                        <template v-slot:title>
                          <span class="font-weight-bolder no-wr"
                            >Selesai &nbsp;</span
                          >
                        </template>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <div class="d-flex justify-content-end">
                <div class="mr-2">
                  <select class="form-control" v-model="filtering.status">
                    <option value="">Semua Status</option>
                    <option value="aktif">Aktif</option>
                    <option value="tidak aktif">Tidak Aktif</option>
                  </select>
                </div>
                <div class="mr-2">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    :placeholder="$t('listTable.search')"
                    class="form-control mr-2"
                  ></b-form-input>
                </div>
                <div v-if="can('Manajemen Truck Owner download')">
                  <b-button variant="primary" @click="exportExcel"
                    >Download Excel</b-button
                  >
                </div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="getItems"
              :fields="fields"
              striped
              hover
              table-class="text-nowrap"
              responsive="lg"
              :perPage="perPage"
              :current-page="currentPage"
              :filter="filter"
              show-empty
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @changePerPage="changePerPage"
              ref="tableFe"
            >
              <template #cell(status_account)="data">
                <span class="text-capitalize">{{
                  data.item.account_status
                }}</span>
              </template>
              <template #cell(status_document)="data">
                <span class="text-capitalize">{{
                  data.item.document_status
                }}</span>
              </template>

              <template #cell(address)="data">
                <span>
                  {{ truncate(data.item.address, 20) }}
                </span>
              </template>

              <template #cell(action)="data">
                <div style="min-width: 80px">
                  <b-button
                    v-if="can('Manajemen Truck Driver read')"
                    v-b-tooltip.hover.top="'Lihat Detail'"
                    variant="secondary"
                    size="sm"
                    @click="gotoShowPage(data.item.id)"
                  >
                    <i class="ti-eye"></i>
                  </b-button>
                </div>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  {{ $t("listTable.show") }}
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    class="ml-1 mr-1"
                    :options="pageOptions"
                  ></b-form-select
                  >{{ $t("listTable.entries") }}
                </label>
              </div>
            </div>
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
